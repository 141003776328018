/*
=============== 
Variables
===============
*/

:root {
  /* COLORS */
  --primary-gold: #b18736;
  --secondy-gold: #dcc97a;

  --primary-blackish: #101010;
  --actual-black: #000000;

  --primary-gray: #f1f1f1;
  --seondary-gray: #fafafa;
  --actual-white: #fff;

  /* dark shades of primary color*/
  --clr-primary-1: #1e1b4b;
  --clr-primary-2: #312e81;
  --clr-primary-3: #4338ca;
  --clr-primary-4: #4f46e5;
  /* primary/main color */
  --clr-primary-5: #6366f1;
  /* lighter shades of primary color */
  --clr-primary-6: #818cf8;
  --clr-primary-7: #a5b4fc;
  --clr-primary-8: #c7d2fe;
  --clr-primary-9: #e0e7ff;
  --clr-primary-10: #eef2ff;
  /* darkest grey - used for headings */
  --clr-grey-1: #0f172a;
  --clr-grey-2: #1e293b;
  --clr-grey-3: #334155;
  --clr-grey-4: #475569;
  /* grey used for paragraphs */
  --clr-grey-5: #64748b;
  --clr-grey-6: #94a3b8;
  --clr-grey-7: #cbd5e1;
  --clr-grey-8: #e2e8f0;
  --clr-grey-9: #f1f5f9;
  --clr-grey-10: #f8fafc;
  --clr-white: #fff;

  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1200px;
  --fixed-width: 620px;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--clr-white);
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  transition: var(--transition);
}
hr {
  border: none;
  border-top: 1px solid var(--primary-gold);
}
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-3);
}

button {
  font-family: inherit;
}

.background-header {
  background: #0e0e0e;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*  global classes */

/* section */
.section {
  padding: 5rem 0;
}
.section-center {
  width: 85vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
.hero-center {
  width: 100vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 90vw;
  }
}
.text-center {
  text-align: center;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 6rem;
  height: 6rem;
  margin: 0 auto;
  margin-top: 10rem;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: var(gray);
  animation: spinner 0.6s linear infinite;
}

.btn {
  text-transform: uppercase;
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  /* border-radius: var(--radius); */
  border-color: transparent;
  color: var(--actual-white);
  background: var(--primary-gold);
}
.btn:hover {
  background: var(--secondy-chocolate);
}

.primary-btn {
  padding: 1rem 2rem;
  color: var(--actual-white);
  background: var(--primary-gold);
}

.primary-btn:hover {
  background: var(--secondy-chocolate);
}

.secondary-btn {
  padding: 1rem 2rem;
  color: var(--actual-black);
  background: transparent;
  border: 1px solid #000;
}
.secondary-btn:hover {
  color: var(--actual-white);
  background: var(--actual-black);
}

.third-btn {
  padding: 1rem 2rem;
  color: var(--actual-white);
  background: var(--actual-black);
}

.third-btn:hover {
  color: var(--actual-white);
  background: var(--primary-blackish);
}

.title {
  text-align: center;
}
.title .underline {
  width: 6rem;
  height: 0.25rem;
  background: var(--primary-gold);
  margin-left: auto;
  margin-right: auto;
}
.page-100 {
  min-height: calc(100vh - 10rem);
  padding: 5rem 0;
}
.page {
  min-height: calc(100vh - (20vh + 10rem));
}
